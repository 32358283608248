export default {
  colors: {
    "bg-canvas": {
      default: "gray.50",
      _dark: "gray.950",
    },
    "bg-surface": {
      default: "white",
      _dark: "gray.900",
    },
    "bg-subtle": {
      default: "gray.50",
      _dark: "gray.800",
    },
    "bg-muted": {
      default: "gray.100",
      _dark: "gray.700",
    },
    default: {
      default: "gray.950",
      _dark: "white",
    },
    inverted: {
      default: "white",
      _dark: "gray.950",
    },
    emphasized: {
      default: "gray.800",
      _dark: "gray.100",
    },
    muted: {
      default: "gray.700",
      _dark: "gray.300",
    },
    subtle: {
      default: "gray.500",
      _dark: "gray.400",
    },
    border: {
      default: "gray.200",
      _dark: "gray.800",
    },
    accent: {
      default: "brand.500",
      _dark: "brand.200",
    },
    success: {
      default: "green.600",
      _dark: "green.200",
    },
    error: {
      default: "red.600",
      _dark: "red.200",
    },
    "bg-accent": "brand.600",
    "bg-accent-subtle": "brand.500",
    "bg-accent-muted": "brand.400",
    "on-accent": "white",
    "on-accent-muted": "brand.50",
    "on-accent-subtle": "brand.100",
  },
};
