import { useNavigation } from "@remix-run/react";

import { Progress, useColorModeValue } from "@chakra-ui/react";

export function TransitionProgress(progressProps) {
  const bg = useColorModeValue("gray.50", "gray.950");

  const navigation = useNavigation();
  const isLoading =
    navigation.state === "submitting" || navigation.state === "loading";
  if (isLoading) {
    return (
      <Progress
        width="100%"
        height="2px"
        bg={bg}
        colorScheme="brand"
        isIndeterminate
        {...progressProps}
      />
    );
  } else {
    return null;
  }
}
