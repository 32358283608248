import { NavLink, useSubmit, Link as RemixLink } from "@remix-run/react";
import {
  Box,
  Badge,
  Container,
  Flex,
  IconButton,
  Spacer,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  Icon,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import { BellIcon as NotificationIcon, CloseIcon } from "@chakra-ui/icons";

import {
  MdOutlineAdd as NewRequestIcon,
  MdOutlineHome as HomeIcon,
  MdOutlineDarkMode as MoonIcon,
  MdOutlineLightMode as SunIcon,
  MdLogin as LoginIcon,
  MdLogout as LogoutIcon,
  MdMenu as MenuIcon,
  MdOutlineReviews as ReviewIcon,
  MdHelpOutline as FAQIcon,
  MdManageAccounts as SettingsIcon,
  MdStar as SubscribeIcon,
} from "react-icons/md";

import { IoPricetagsOutline as PricingIcon } from "react-icons/io5";

import { TransitionProgress, Footer, Logo, Paper } from "~/components";

import {
  useFlashMessage,
  useOptionalUser,
  useUserSubscriptionValue,
} from "~/hooks";

const HEIGHT = "60px";

export function StaticLayout({ ActionButton, children }) {
  return (
    <Flex
      as="section"
      minH="100vh"
      direction="column"
      bg="bg-canvas"
      pos="relative"
    >
      <Paper
        as="nav"
        display="flex"
        alignItems="center"
        height={HEIGHT}
        zIndex={1200}
        pos="sticky"
        top={0}
        p={0}
        borderRadius={0}
      >
        <Container maxW="container.lg">
          <Flex alignItems="center">
            <Logo />
            <Spacer />
            <HStack spacing={4}>
              {ActionButton}
              <Menu colorScheme="teal" autoSelect={false}>
                {({ isOpen }) => {
                  return (
                    <MenuButton
                      colorScheme="brand"
                      as={IconButton}
                      aria-label="Toggle menu"
                      icon={
                        <Icon
                          as={isOpen ? CloseIcon : MenuIcon}
                          boxSize={isOpen ? undefined : 6}
                        />
                      }
                      isDisabled
                      variant="outline"
                    />
                  );
                }}
              </Menu>
            </HStack>
          </Flex>
        </Container>
      </Paper>

      <Container maxW="container.lg" my={{ base: 4, md: 8 }} p={0} as="main">
        {children}
      </Container>
      <Footer />
    </Flex>
  );
}

export default function Layout({ children }) {
  useFlashMessage();

  return (
    <Flex
      as="section"
      minH="100vh"
      direction="column"
      bg="bg-canvas"
      pos="relative"
    >
      <Paper
        as="nav"
        display="flex"
        alignItems="center"
        height={HEIGHT}
        zIndex={1200}
        pos="sticky"
        top={0}
        p={0}
        borderRadius={0}
      >
        <Container maxW="container.lg">
          <Flex alignItems="center">
            <Logo />
            <Spacer />
            <NavItems />
          </Flex>
        </Container>

        <TransitionProgress pos="absolute" bottom="-2px" />
      </Paper>

      <Container maxW="container.lg" my={{ base: 4, md: 8 }} p={0} as="main">
        {children}
      </Container>
      <Footer />
    </Flex>
  );
}

function NavItems() {
  const UsageButton = useUserSubscriptionValue({
    none: () => (
      <Button
        variant="primary"
        fontWeight="bold"
        as={RemixLink}
        to="/new"
        align="flex-end"
      >
        Create a request
      </Button>
    ),
    trial: (user) => (
      <Button
        variant="warning"
        fontWeight="bold"
        as={RemixLink}
        to="/account"
        leftIcon={<Icon as={NotificationIcon} boxSize={5} />}
        align="flex-end"
      >
        {user.subscription.trialNotificationsLeft} remaining
      </Button>
    ),
    active_flexible: () => (
      <Button
        variant="primary"
        fontWeight="bold"
        as={RemixLink}
        to="/account"
        leftIcon={<Icon as={NotificationIcon} boxSize={5} />}
      >
        View usage
      </Button>
    ),
    active: () => (
      <Button variant="primary" fontWeight="bold" as={RemixLink} to="/new">
        Create a request
      </Button>
    ),
  });

  return (
    <HStack spacing={4}>
      {UsageButton}
      <NavMenu />
    </HStack>
  );
}

function NavMenu() {
  const submit = useSubmit();
  const user = useOptionalUser();

  const { colorMode, toggleColorMode } = useColorMode();
  const menuItemActive = useColorModeValue("gray.100", "whiteAlpha.100");

  const SubscribeMenuItem = useUserSubscriptionValue({
    none: () => null,
    trial: () => (
      <NavLink to="/subscribe">
        {({ isActive }) => {
          return (
            <MenuItem
              alignItems="flex-start"
              bg={isActive ? menuItemActive : undefined}
              color="warning"
              icon={<Icon as={SubscribeIcon} boxSize={6} />}
            >
              Subscribe
            </MenuItem>
          );
        }}
      </NavLink>
    ),
    active: () => null,
    active_basic: () => (
      <NavLink to="/subscribe">
        {({ isActive }) => {
          return (
            <MenuItem
              alignItems="flex-start"
              bg={isActive ? menuItemActive : undefined}
              color="warning"
              icon={<Icon as={SubscribeIcon} boxSize={6} />}
            >
              Upgrade
            </MenuItem>
          );
        }}
      </NavLink>
    ),
  });

  if (user) {
    return (
      // Box wrapper needed as temporary workaround for an outstanding Chakra issue
      // https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911
      <Box>
        <Menu colorScheme="teal" autoSelect={false}>
          {({ isOpen }) => {
            return (
              <>
                <MenuButton
                  colorScheme="brand"
                  as={IconButton}
                  aria-label="Toggle menu"
                  icon={
                    <Icon
                      as={isOpen ? CloseIcon : MenuIcon}
                      boxSize={isOpen ? undefined : 6}
                    />
                  }
                  variant="outline"
                />
                <MenuList>
                  <Box px={4} py={2}>
                    <Text color="subtle">Logged in as</Text>
                    <Text color="accent" fontWeight="bold">
                      {user.email}
                    </Text>
                  </Box>
                  <MenuDivider />
                  <NavLink to="/browse">
                    {({ isActive }) => {
                      return (
                        <MenuItem
                          alignItems="flex-start"
                          bg={isActive ? menuItemActive : undefined}
                          icon={<Icon as={HomeIcon} boxSize={6} />}
                        >
                          Home
                        </MenuItem>
                      );
                    }}
                  </NavLink>
                  <NavLink to="/new">
                    {({ isActive }) => {
                      return (
                        <MenuItem
                          alignItems="flex-start"
                          bg={isActive ? menuItemActive : undefined}
                          icon={<Icon as={NewRequestIcon} boxSize={6} />}
                        >
                          New Request
                        </MenuItem>
                      );
                    }}
                  </NavLink>
                  <NavLink to="/help">
                    {({ isActive }) => {
                      return (
                        <MenuItem
                          alignItems="flex-start"
                          bg={isActive ? menuItemActive : undefined}
                          icon={<Icon as={FAQIcon} boxSize={6} />}
                        >
                          Help
                        </MenuItem>
                      );
                    }}
                  </NavLink>
                  <MenuDivider />
                  {SubscribeMenuItem}
                  <NavLink to="/account">
                    {({ isActive }) => {
                      return (
                        <MenuItem
                          alignItems="flex-start"
                          bg={isActive ? menuItemActive : undefined}
                          icon={<Icon as={SettingsIcon} boxSize={6} />}
                        >
                          Your Account
                        </MenuItem>
                      );
                    }}
                  </NavLink>
                  <MenuDivider />
                  <MenuItem
                    alignItems="flex-start"
                    icon={
                      colorMode === "light" ? (
                        <Icon as={MoonIcon} boxSize={6} />
                      ) : (
                        <Icon as={SunIcon} boxSize={6} />
                      )
                    }
                    onClick={toggleColorMode}
                    closeOnSelect={false}
                  >
                    Toggle {colorMode === "light" ? "Dark" : "Light"} Mode
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    alignItems="flex-start"
                    icon={<Icon as={LogoutIcon} boxSize={6} />}
                    color="error"
                    onClick={() => {
                      submit(null, { method: "post", action: "/logout" });
                    }}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </>
            );
          }}
        </Menu>
      </Box>
    );
  }

  return (
    // Box wrapper needed as temporary workaround for an outstanding Chakra issue
    // https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911
    <Box>
      <Menu colorScheme="teal" autoSelect={false}>
        {({ isOpen }) => {
          return (
            <>
              <MenuButton
                colorScheme="brand"
                as={IconButton}
                aria-label="Toggle menu"
                icon={
                  <Icon
                    as={isOpen ? CloseIcon : MenuIcon}
                    boxSize={isOpen ? undefined : 6}
                  />
                }
                variant="outline"
              />
              <MenuList>
                <NavLink to="/" end>
                  {({ isActive }) => {
                    return (
                      <MenuItem
                        alignItems="flex-start"
                        bg={isActive ? menuItemActive : undefined}
                        icon={<Icon as={HomeIcon} boxSize={6} />}
                      >
                        Home
                      </MenuItem>
                    );
                  }}
                </NavLink>
                <NavLink to="/faq">
                  {({ isActive }) => {
                    return (
                      <MenuItem
                        alignItems="flex-start"
                        bg={isActive ? menuItemActive : undefined}
                        icon={<Icon as={FAQIcon} boxSize={6} />}
                      >
                        FAQs
                      </MenuItem>
                    );
                  }}
                </NavLink>
                <NavLink to="/reviews">
                  {({ isActive }) => {
                    return (
                      <MenuItem
                        alignItems="flex-start"
                        bg={isActive ? menuItemActive : undefined}
                        icon={<Icon as={ReviewIcon} boxSize={6} />}
                      >
                        Reviews
                      </MenuItem>
                    );
                  }}
                </NavLink>
                <NavLink to="/pricing">
                  {({ isActive }) => {
                    return (
                      <MenuItem
                        alignItems="flex-start"
                        bg={isActive ? menuItemActive : undefined}
                        icon={<Icon as={PricingIcon} boxSize={6} />}
                      >
                        Pricing
                      </MenuItem>
                    );
                  }}
                </NavLink>
                <MenuDivider />
                <MenuItem
                  alignItems="flex-start"
                  icon={
                    colorMode === "light" ? (
                      <Icon as={MoonIcon} boxSize={6} />
                    ) : (
                      <Icon as={SunIcon} boxSize={6} />
                    )
                  }
                  onClick={toggleColorMode}
                  closeOnSelect={false}
                >
                  Toggle {colorMode === "light" ? "Dark" : "Light"} Mode
                </MenuItem>
                <MenuDivider />
                <NavLink to="/login">
                  {({ isActive }) => {
                    return (
                      <MenuItem
                        alignItems="flex-start"
                        bg={isActive ? menuItemActive : undefined}
                        color="accent"
                        icon={<Icon as={LoginIcon} boxSize={6} />}
                      >
                        Login
                      </MenuItem>
                    );
                  }}
                </NavLink>
              </MenuList>
            </>
          );
        }}
      </Menu>
    </Box>
  );
}
