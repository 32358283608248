export default {
  xs: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  sm: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 2px 4px rgba(48, 49, 51, 0.1)",
  md: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)",
  lg: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1)",
  xl: "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)",
  "xs-dark": "0px 1px 3px rgba(11, 12, 17, 0.9)",
  "sm-dark": "0px 2px 4px rgba(11, 12, 17, 0.9)",
  "md-dark": "0px 4px 8px rgba(11, 12, 17, 0.9)",
  "lg-dark": "0px 8px 16px rgba(11, 12, 17, 0.9)",
  "xl-dark": "0px 16px 24px rgba(11, 12, 17, 0.9)",
};
