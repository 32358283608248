import { Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";

import { useContext, useEffect } from "react";
import { withEmotionCache } from "@emotion/react";

import ClientStyleContext from "./clientStyleContext";
import ServerStyleContext from "./serverStyleContext";

export default withEmotionCache(
  ({ env, user, colorMode, children }, emotionCache) => {
    const serverStyleData = useContext(ServerStyleContext);
    const clientStyleData = useContext(ClientStyleContext);

    // Only executed on client
    useEffect(() => {
      // re-link sheet container
      emotionCache.sheet.container = document.head;
      // re-inject tags
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        emotionCache.sheet._insertTag(tag);
      });
      // reset cache to reapply global styles
      clientStyleData?.reset();
    }, []);

    return (
      <html
        lang="en"
        {...(colorMode && {
          "data-theme": colorMode,
          style: { colorScheme: colorMode },
        })}
      >
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <meta name="apple-mobile-web-app-title" content="Schnerp" />
          <link
            rel="icon"
            type="image/png"
            href="/favicon-48x48.png"
            sizes="48x48"
          />
          <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <Meta />
          <Links />
          {serverStyleData?.map(({ key, ids, css }) => (
            <style
              key={key}
              data-emotion={`${key} ${ids.join(" ")}`}
              dangerouslySetInnerHTML={{ __html: css }}
            />
          ))}
          {/* PLAUSIBLE INTEGRATION */}
          {(env?.APP_ENV === "production" || env?.APP_ENV === "preview") && (
            <>
              <script
                defer
                src="/stats/js/script.tagged-events.js"
                data-api="/stats/api/event" // Proxy through nginx to avoid blockers
                data-domain={new URL(env.BASE_URL).host.replace("www.", "")} // Remove www for prod environment
              ></script>
            </>
          )}
          {/* REWARDFUL INTEGRATION */}
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
            }}
          />
          {env?.APP_ENV === "production" || env?.APP_ENV === "preview" ? (
            <script
              defer
              src="/stats/js/rw.js" // Proxy through nginx to avoid blockers
              data-rewardful={env?.REWARDFUL_API_KEY}
            />
          ) : (
            <script
              defer
              src="https://r.wdfl.co/rw.js"
              data-rewardful={env?.REWARDFUL_API_KEY}
            />
          )}
        </head>
        <body
          {...(colorMode && {
            className: `chakra-ui-${colorMode}`,
          })}
        >
          {children}
          {env?.APP_ENV !== "test" && <ScrollRestoration />}
          <script
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={{
              __html: `window.env=${JSON.stringify(env)};`,
            }}
          />
          <script
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={{
              __html: `window.user=${JSON.stringify(user)};`,
            }}
          />
          {/* START POSTMARK REBOUND INTEGRATION */}
          {env && user && (
            <script
              suppressHydrationWarning={true}
              dangerouslySetInnerHTML={{
                __html: `window.ReboundSettings = {
                  publicToken: "${env?.POSTMARK_REBOUND_TOKEN}",
                  email: "${user?.email}",
                  title: "Your notifications aren't working",
                  actionLabel: "Update email address",
                  actionUrl: "${env?.BASE_URL}/account",
                  description: "We can't send emails to [[email]] **Please update your email address**, or [message us](mailto:help@schnerp.com?Subject=Update%20email%20for%20[[email]]) for help.",
                  fallbackDescription: "We can't send emails to [[email]] **Please update your email address**, or [message us](mailto:help@schnerp.com?Subject=Update%20email%20for%20[[email]]) for help.",
                };`,
              }}
            />
          )}
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(r,e,b,o,u,n,d){if(r.Rebound)return;d=function(){o="script";u=e.createElement(o);u.type="text/javascript";u.src=b;u.async=true;n=e.getElementsByTagName(o)[0];n.parentNode.insertBefore(u,n)};if(r.attachEvent){r.attachEvent("onload",d)}else{r.addEventListener("load",d,false)}})(window,document,"https://rebound.postmarkapp.com/widget/1.0");`,
            }}
          />
          <Scripts />
        </body>
      </html>
    );
  },
);
