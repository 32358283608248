import { mode, getColor, transparentize } from "@chakra-ui/theme-tools";

const variants = {
  outline: (props) => ({
    border: "1px solid",
    borderColor: "inherit",
    bg: mode("white", "gray.900")(props),

    _hover: {
      borderColor: mode("gray.300", "whiteAlpha.400")(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      borderColor: "inherit",
    },
    _readOnly: {
      boxShadow: "none !important",
      userSelect: "all",
    },
    _invalid: {
      borderColor: getColor(props.theme, mode("red.500", "red.300")(props)),
      boxShadow: `0 0 0 1px ${getColor(
        props.theme,
        mode("red.500", "red.300")(props),
      )}`,
    },
    _focus: {
      borderColor: mode("brand.500", "brand.200")(props),
      boxShadow: mode(
        `0px 0px 0px 1px ${transparentize(`brand.500`, 1.0)(props.theme)}`,
        `0px 0px 0px 1px ${transparentize(`brand.200`, 1.0)(props.theme)}`,
      )(props),
    },
    _expanded: {
      borderColor: mode("brand.500", "brand.200")(props),
      boxShadow: mode(
        `0px 0px 0px 1px ${transparentize(`brand.500`, 1.0)(props.theme)}`,
        `0px 0px 0px 1px ${transparentize(`brand.200`, 1.0)(props.theme)}`,
      )(props),
    },
  }),
};

const sizes = {
  sm: {
    px: 3,
    h: 8,
    fontSize: "sm",
    borderRadius: "sm",
  },
  md: {
    px: 4,
    h: 10,
    fontSize: "md",
    borderRadius: "md",
  },
  lg: {
    px: 4,
    h: 12,
    fontSize: "lg",
    borderRadius: "md",
  },
};

export default {
  variants,
  sizes,
  defaultProps: {
    size: "md",
    variant: "outline",
    colorScheme: "brand",
  },
};
